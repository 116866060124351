<template>
  <div class="section">
    <nav class="level">
      <div class="level-left">
        <div class="level-item inline">
          <h1 class="title-section">Comentarios </h1>
          <div @click="$router.go(-1)" class="header-content">
            <span class="material-icons arrow-cursor">
              chevron_left
            </span>
            <label class="vertical-center">Regresar</label>
          </div>
        </div>
      </div>
    </nav>
    <loading v-if="loading"/>
    <div class="columns is-multiline" v-else>
      <div class="column is-12">
        <base-table
          class="is-bordered is-narrow is-striped-2 is-fullwidth is-hoverable bankingTable"
          :columns="columnsData"
          :data="postList"
          :loading="loading"
        >
        <div
            slot="config"
            slot-scope="scope"
          >
            <div class="field">
              <input :id="`active_${scope.row.id}`" type="checkbox" @change="selectStatus(scope.row.id)" :name="`active_${scope.row.id}`" class="switch is-rounded is-small" :checked="scope.row.active ? 'checked': ''">
              <label :for="`active_${scope.row.id}`"></label>
            </div>
          </div>
        </base-table>
        <base-pagination
          class="is-medium"
          :page="page"
          :pages="pages"
          :action="paginator"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'

const DEFAULT_DISPLAY_SIZE = 10

export default {
  components: {
    BaseTable: () => import('@/components/Table'),
    BasePagination: () => import('@/components/BasePagination'),
    Loading: () => import('@/components/ui/Loading')
  },
  data () {
    return {
      columnsData: [
        {
          id: 'id',
          header: 'Id',
          class: 'column-mobile',
          accessor: (row) => `${row.id}`
        },
        {
          id: 'entrada',
          header: 'Entrada',
          class: 'column-mobile',
          accessor: (row) => `${row.entrada}`
        },
        {
          id: 'name',
          header: 'Nombre',
          class: 'column-mobile',
          accessor: (row) => `${row.name}`
        },
        {
          id: 'comment',
          header: 'Comentario',
          class: 'column-mobile',
          accessor: (row) => `${row.comment}`
        },
        {
          id: 'email',
          header: 'E-MAIL',
          class: 'column-mobile',
          accessor: (row) => `${row.email}`
        },
        {
          id: 'created_at',
          header: 'Fecha de creacion',
          class: 'column-mobile',
          accessor: (row) => moment(row.created_at).format('DD-MM-YYYY HH:mm:ss')
        },
        { id: 'config', name: 'config', header: 'Acciones', class: 'column-mobile has-text-centered' }
      ],
      postList: [],
      page: 1,
      pages: 1,
      loading: false
    }
  },
  methods: {
    ...mapActions(['getBlogComments', 'changeStatus']),
    selectStatus (commitId) {
      this.changeStatus(commitId)
    },
    paginator (page) {
      this.page = page
      this.loading = true
      this.$router.push({ name: 'comentarios-blog', query: { page } })
      this.getBlogComments({ page, size: DEFAULT_DISPLAY_SIZE })
        .then(resp => {
          this.postList = resp.payload
          this.pages = resp.totalPage
          this.loading = false
        })
    }
  },
  beforeMount () {
    const { page } = this.$router.history.current.query
    this.page = (page === '' || page === undefined) ? this.page : parseInt(page, 10)
    this.getBlogComments({ page: this.page, size: DEFAULT_DISPLAY_SIZE })
      .then(resp => {
        this.postList = resp.payload
        this.pages = resp.totalPage
        this.loading = false
      })
  }
}
</script>

<style scoped>
  .section {
    padding: 0 20px 0 20px;
  }
  .inline {
    display: inline;
  }
  .header-content {
    display: inline;
    color: #3b5088;
  }
  .vertical-center {
    position: absolute;
    padding: 0px;
    margin-left: 0px;
    font-size: 15px !important;
    cursor: pointer;
  }
</style>
